<template>
  <b-card>
    <div class="d-flex">
      <b-form-input
          id="filter-input"
          v-model="filter"
          type="search"
          class="col-md-4 col-xl-2"
          placeholder="Cerca..."
      ></b-form-input>
      <b-button class="mb-3 ml-auto" variant="success" v-b-modal.editModalOperator
                @click="newItem"
      >+ Crea Nuovo</b-button
      >
    </div>
    <b-table
        hover
        bordered
        :filter="filter"
        responsive
        :items="items"
        :fields="fields"
        :current-page="currentPage"
        :per-page="perPage"
        class="contact-table"
    >
      <!-- A custom formatted column -->
      <template #cell(Azioni)="data" width="100" class="text-center">
        <a class="mx-1 cursor-pointer" @click="editItem(data.item)"
        ><feather type="edit-3" class="feather-sm"></feather
        ></a>
        <a
            class="mx-1 cursor-pointer text-danger"
            @click="deleteItem(data.item)"
        ><feather type="trash-2" class="text-danger feather-sm"></feather
        ></a>
      </template>
    </b-table>
    <b-pagination
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        align="center"
    ></b-pagination>
    <!-- ====================================== -->
    <!-- Edit Contact box -->
    <!-- ====================================== -->
    <b-modal
        ref="edit-modal-operator"
        id="editModalOperator"
        size="xl"
        hide-header
        @ok="save"
        @cancel="close"
        ok-title="Salva"
        cancel-title="Chiudi"
    >
      <h4 class="mb-4">{{ formTitle }}</h4>
      <b-row>
        <b-col cols="12" lg="6" class="mb-3">
          <h6>Name</h6>
          <b-form-input
              v-model="editedItem.authinfo.name"
              placeholder="Inserisci Nome"
              name="name-group"
              size="lg"
          ></b-form-input>
        </b-col>
        <b-col cols="12" lg="6" class="mb-3">
          <h6>Email</h6>
          <b-form-input
              v-model="editedItem.authinfo.email"
              placeholder="Inserisci email"
              name="name-group"
              size="lg"
          ></b-form-input>
        </b-col>
        <b-col cols="12" lg="6" class="mb-3">
          <h6>Password</h6>
          <b-form-input
              v-model="editedItem.authinfo.password"
              placeholder="Inserisci password"
              name="name-group"
              size="lg"
          ></b-form-input>
        </b-col>
        <b-col cols="12" lg="6" class="mb-3">
          <h6>Associa Vettore</h6>
          <VectorsSelect ref="vectorsSelect" />
        </b-col>
      </b-row>
    </b-modal>
  </b-card>
</template>

<script>
export default {
  name: "OperatorList",
  components: {
    VectorsSelect: () => import('@/views/users/components/VectorsSelect')
  },
  user(){
    return this.$store.state.authentication;
  },
  data: () => ({
    sortBy: "id",
    fields: [
      { key: "id", sortable: true },
      { key: "authinfo.name", label: "Nome" },
      { key: "authinfo.email", label: "Email" },
      "Azioni",
    ],
    filter: null,
    totalRows: 1,
    currentPage: 1,
    perPage: 5,
    url: null,
    editedIndex: -1,
    editedItem: {
      id: "",
      authinfo: {
        name: '',
        email: '',
        password: '',
      },
      user_vector_id: '',
    },
    defaultItem: {
      id: "",
      authinfo: {
        name: '',
        email: '',
        password: '',
      },
      user_vector_id: '',
    },
  }),
  created() {
    this.initialize();
  },
  methods: {
    async initialize() {
      await this.$store.dispatch("setDataTable", [])
      const res = await this.axios.get('user/operators/')

      await this.$store.dispatch("setDataTable", res.data)
    },
    editItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = item;
      this.$refs["edit-modal-operator"].show();
      const that = this
      setTimeout(function () {
        that.$refs.vectorsSelect.value = item.user_vector_id
      }, 400)
    },

    newItem() {
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
      const that = this
      setTimeout(function () {
        if (that.user.user_rule === 'vector') {
          that.$refs.vectorsSelect.value = that.user.user.id
          that.$refs.vectorsSelect.disable = true
          that.editedItem.vector_id = that.user.user.id
        }
      }, 400)
    },

    async deleteItem(item) {
      const index = this.items.indexOf(item);
      await this.$swal.fire({
        title: 'Elimina Utente',
        text: 'Sei sicuro di voler eliminare '+item.authinfo.name+'?',
        icon: 'warning',
        confirmButtonColor: '#1f2335',
        confirmButtonText: 'Si, Elimina!',
        showCancelButton: true,
        cancelButtonText: 'No!'
      }).then(async result => {
        if (result.value) {
          const res = await this.axios.delete('user/operator/'+item.id)

          if (res.data.status === 'success') {
            this.items.splice(index, 1);
          }

          this.$swal.fire({
            title: 'Eliminazione Utente',
            text: res.data.message,
            icon: res.data.status,
            confirmButtonColor: '#1f2335',
          })
        }
      });

      await this.initialize()
    },
    close() {
      this.$refs["edit-modal-operator"].hide();
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },
    async save() {
      this.editedItem.user_vector_id = this.$refs.vectorsSelect.value
      const res = await this.axios.post('user/operator', this.editedItem)
      this.$swal.fire({
        title: 'Salvataggio Utente',
        text: res.data.message,
        icon: res.data.status,
        confirmButtonColor: '#1f2335',
      })
      this.close();
      await this.initialize();
    },
    preventNumberInput() {
      const that = this
      setTimeout(function () {
        if (that.editedItem.number_ambulance < 1) {
          that.editedItem.number_ambulance = 1
        }
      }, 300)
    },
    preventCostKm() {
      const that = this
      setTimeout(function () {
        if (that.editedItem.cost_by_km < 0.5) {
          that.editedItem.cost_by_km = 0.5
        }
      }, 300)
    }
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Nuovo Operatore" : "Modifica Operatore";
    },
    rows() {
      return this.items.length;
    },
    items () {
      return this.$store.state.dataTable
    },
    user() {
      return this.$store.state.authentication;
    }
  },
  watch: {},
};
</script>

<style>
.contact-table td {
  vertical-align: middle;
}
</style>